<template>
  <v-container fluid
               class="tfb-smaller-container tfb-component-container"
               v-if="contactList.length > 0">
    <v-row>
      <v-col cols="12">
        <v-container fluid
                     class="drop-shadow background-white px-md-10 pb-5">
          <v-row>
            <v-col cols="12">
              <h2 class="tfb-title-text-small text-uppercase">
                Manage Phone Numbers
              </h2>
            </v-col>
          </v-row>
          <phone-verification></phone-verification>
          <enable-text-dialog></enable-text-dialog>
          <v-data-table :headers="phoneHeaders"
                        :items="contactList"
                        :loading="phoneLoading"
                        hide-default-footer
                        disable-sort>
            <template v-slot:item.TextEnabled="{ item }">
            </template>
            <template v-slot:item.Verified="{ item }">
              <span v-if="item.PhoneNumber === null || item.PhoneNumber === ''">
                No
              </span>
              <span v-else-if="!item.TextEnabled && phoneVerificationEnabled === 'Y'">
                <a href=""
                   v-on:click.prevent="showTextEnable = true">How do I enable texts?</a>
              </span>
              <span v-else-if="!item.Verified && phoneVerificationEnabled === 'Y'">
                <a href=""
                   v-on:click.prevent="sendVerifyPhone(item.MiraId, item.PhoneNumber)">Click here to verify your phone number</a>
              </span>
              <span v-else-if="item.Verified">Verified</span>
              <span v-else>No</span>
            </template>
          </v-data-table>
          <!-- Update Contact Phone Numbers -->
          <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
            <v-form role="form"
                    name="updateContactForm"
                    ref="updateContactForm"
                    @submit.stop.prevent="handleSubmit(updatePhoneNumber)"
                    v-if="contactList && contactList.length > 0 && contactUpdateEnabled === 'Y'">
              <v-row>
                <v-col cols="12"
                       sm="4">
                  <select-input rules="required"
                                label="Contact Name"
                                :items="contactList"
                                item-text="FullName"
                                return-object
                                v-model="updateContact"></select-input>
                </v-col>
                <v-col cols="12"
                       sm="4">
                  <text-input rules="required|phone_number|min:10|max:12"
                              autocomplete="home tel"
                              minlength="10"
                              maxlength="12"
                              label="New Phone Number"
                              v-model="updatedPhoneNumber"
                              v-mask="'###-###-####'"
                              :disabled="formDisabled"
                              type="tel"></text-input>
                </v-col>
                <v-col cols="12" sm="4">
                  <v-btn block
                         :disabled="formDisabled"
                         type="submit"
                         class="tfb-button background-red">Update Phone Number</v-btn>
                </v-col>
              </v-row>
            </v-form>
          </ValidationObserver>
          <!-- Add New Contact -->
              <v-row v-if="contactUpdateEnabled === 'Y'">
                <v-col cols="12"
                       sm="4">
                  <v-btn block
                         @click="addMemberShow()"
                         :disabled="formDisabled"
                         v-show="contactList && contactList.length >= 1"
                         class="tfb-button background-red">Add New Contact</v-btn>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
              <ValidationObserver ref="obv" v-slot="{ handleSubmit }">
                <v-form role="form"
                        name="addContactForm"
                        ref="addContactForm"
                        @submit.stop.prevent="handleSubmit(addContact)"
                        v-if="showPhoneAdd">
                  <!-- First Name -->
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required|name|min:2|max:25"
                                  minlength="2"
                                  maxlength="25"
                                  label="First Name"
                                  v-model="firstName"
                                  autocomplete="shipping given-name"
                                  autocapitalize="words"></text-input>
                    </v-col>
                  </v-row>
                  <!-- Last Name -->
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required|name|min:2|max:25"
                                  minlength="2"
                                  maxlength="25"
                                  label="Last Name"
                                  v-model="lastName"
                                  autocomplete="shipping family-name"
                                  autocapitalize="words"></text-input>
                    </v-col>
                  </v-row>
                  <!-- Relationship -->
                  <v-row>
                    <v-col cols="12">
                      <select-input rules="required"
                                    label="Relationship to Member"
                                    :items="relationshipOptions"
                                    item-text="Name"
                                    item-value="Id"
                                    v-model="relationship"></select-input>
                    </v-col>
                  </v-row>
                  <!-- Gender -->
                  <v-row>
                    <v-col cols="12">
                      <select-input rules="required"
                                    label="Gender"
                                    :items="genderOptions"
                                    item-text="Name"
                                    item-value="Id"
                                    v-model="gender"></select-input>
                    </v-col>
                  </v-row>
                  <!-- Birth Date -->
                  <v-row>
                    <v-col cols="12">
                      <v-menu ref="birthDateMenu"
                              v-model="birthDateMenu"
                              :close-on-content-click="false"
                              transition="scale-transition"
                              offset-y
                              max-width="290px"
                              min-width="290px">
                        <template v-slot:activator="{ on, attrs }">
                          <ValidationProvider tag="div"
                                              :bails="false"
                                              rules="required"
                                              name="Date of Birth"
                                              v-slot="{ errors, valid }"
                                              vid="birth">
                            <v-text-field autocomplete="dob"
                                          outlined
                                          required
                                          readonly
                                          hide-details="auto"
                                          :error-messages="errors"
                                          :success="valid"
                                          v-model="dateOfBirth"
                                          v-mask="'##/##/####'"
                                          label="Date of Birth"
                                          @change="unformattedDateOfBirth = parseDate(dateOfBirth)"
                                          v-bind="attrs"
                                          v-on="on">
                              <template v-slot:append>
                                <v-icon>mdi-calendar</v-icon>
                                <v-icon>mdi-asterisk</v-icon>
                              </template>
                            </v-text-field>
                          </ValidationProvider>
                        </template>
                        <v-date-picker ref="picker"
                                       v-model="unformattedDateOfBirth"
                                       :active-picker.sync="dateOfBirthActivePicker"
                                       color="background-blue"
                                       no-title
                                       @input="birthDateMenu = false"
                                       :max="maxDateOfBirth"
                                       min="1920-01-01"></v-date-picker>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <!-- Phone Number -->
                  <v-row>
                    <v-col cols="12">
                      <text-input rules="required|phone_number|min:10|max:12"
                                  autocomplete="home tel"
                                  minlength="10"
                                  maxlength="12"
                                  label="Phone Number"
                                  v-model="phoneNumber"
                                  v-mask="'###-###-####'"
                                  type="tel"></text-input>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-spacer></v-spacer>
                    <v-col cols="12" sm="4">
                      <v-btn block
                             :disabled="formDisabled"
                             type="submit"
                             class="tfb-button background-red">Submit</v-btn>
                    </v-col>
                  </v-row>
                </v-form>
              </ValidationObserver>
              <v-overlay absolute
                         :value="saveOverlay">
                <v-progress-circular indeterminate
                                     size="64"></v-progress-circular>
              </v-overlay>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  /* eslint prefer-destructuring: 0 */
  /* eslint object-shorthand: 0 */
  /* eslint no-unused-vars: 0 */
  /* eslint no-underscore-dangle: 0 */
  import { subYears } from 'date-fns';
  import { dispatch, sync } from 'vuex-pathify';
  import { mask } from 'vue-the-mask';
  import { ValidationObserver, ValidationProvider } from 'vee-validate';
  import tfbHelperMixin from '@/mixins/helpers';
  import { insuredAccessOnlyErrorMessage } from '@/mixins/errorMessages';
  import PhoneVerification from './PhoneVerification.vue';
  import EnableTextDialog from './EnableTextInstructions.vue';

  export default {
    name: 'PhoneNumbers',
    mixins: [tfbHelperMixin],
    directives: { mask },
    props: {
      contactList: {
        type: Array,
        required: true,
      },
      phoneLoading: {
        type: Boolean,
        required: true,
      },
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      PhoneVerification,
      EnableTextDialog,
    },
    data() {
      return {
        saveOverlay: false,
        formDisabled: false,
        dateOfBirthActivePicker: null,
        birthDateMenu: false,
        phoneHeaders: [
          { text: 'Contact Name', align: 'start', value: 'FullName' },
          { text: 'Phone Number', value: 'PhoneNumber' },
          { text: 'Verified', value: 'Verified' },
        ],
        genderOptions: null,
        relationshipOptions: null,
        firstName: null,
        lastName: null,
        gender: null,
        relationship: null,
        unformattedDateOfBirth: null,
        phoneNumber: null,
        updateContact: null,
        updatedPhoneNumber: null,
      };
    },
    computed: {
      maxDateOfBirth() {
        return this.parseDate(subYears(new Date(), 14).toISOString());
      },
      dateOfBirth() {
        return this.formatDate(this.unformattedDateOfBirth);
      },
      memberId() {
        try {
          return this.$store.getters['member/memberId'];
        } catch (error) {
          return '0';
        }
      },
      memberNumber() {
        try {
          return this.$store.getters['member/memberNumber'];
        } catch (error) {
          return '';
        }
      },
      phoneVerificationEnabled() {
        try {
          return this.$store.getters['app/phoneVerificationEnabled'];
        } catch (error) {
          return 'N';
        }
      },
      contactUpdateEnabled() {
        try {
          return this.$store.getters['app/contactUpdateEnabled'];
        } catch (error) {
          return 'N';
        }
      },
      showPhoneVerification: sync('member/showPhoneVerification'),
      showPhoneAdd: sync('member/showPhoneAdd'),
      showTextEnable: sync('member/showTextEnable'),
    },
    watch: {
      birthDateMenu(val) {
        setTimeout(() => { (this.dateOfBirthActivePicker = 'YEAR'); });
      },
    },
    methods: {
      getGenderList() {
        dispatch('member/getGenders')
          .then((response) => {
            this.genderOptions = response;
          })
          .catch((error) => {
            if (error.toString().indexOf('code 401') >= 0) {
              // Session has timed out
              this.$router.push({ name: 'policyholderLogin' });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      getRelationshipsList() {
        dispatch('member/getRelationships')
          .then((response) => {
            this.relationshipOptions = response;
          })
          .catch((error) => {
            if (error.toString().indexOf('code 401') >= 0) {
              // Session has timed out
              this.$router.push({ name: 'policyholderLogin' });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      },
      addMemberShow() {
        this.showPhoneAdd = !this.showPhoneAdd;

        if (this.genderOptions === null || this.genderOptions.length < 1) {
          this.getGenderList();
        }

        if (this.getRelationshipsList === null || this.getRelationshipsList.length < 1) {
          this.getRelationshipsList();
        }
      },
      onClose() {
        this.showPhoneVerification = false;
        this.showPhoneAdd = false;
      },
      sendVerifyPhone(miraId, phoneNumber) {
        dispatch('app/clearErrorMessage');
        if (!this.isMemberRole) {
          // Not the member, don't allow changes
          this.$store.dispatch('app/setErrorMessage', insuredAccessOnlyErrorMessage);
        } else {
          this.saveOverlay = true;
          dispatch('member/sendPhoneVerification', {
            sMiraId: miraId, sOnlineAccountId: this.memberId, sPhoneNumber: phoneNumber, sMemberNumber: this.memberNumber, sTfbId: this.trackingId,
          })
            .then(() => {
              // Verification sent
              this.showPhoneVerification = true;
              this.saveOverlay = false;
            })
            .catch((error) => {
              dispatch('app/setErrorMessage', error);
              this.saveOverlay = false;
            });
        }
      },
      updatePhoneNumber() {
        dispatch('app/clearErrorMessage');
        this.phoneSuccess = false;
        this.saveOverlay = true;
        dispatch('member/updatePhoneNumber', {
          sMiraId: this.updateContact.MiraId, sPhoneNumber: this.updatedPhoneNumber, sMemberNumber: this.memberNumber, sTfbId: this.trackingId,
        })
          .then((updateResponse) => {
            if (updateResponse && updateResponse === true) {
              // Update was successful
              this.updatedPhoneNumber = null;
              this.updateContact = null;

              // Refresh the phone number list
              dispatch('member/getPhoneNumbers', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId });
            } else {
              dispatch('app/setErrorMessage', 'Unable to update phone number. Please verify the phone number and try again.');
            }
            this.saveOverlay = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
      addContact() {
        dispatch('app/clearErrorMessage');
        this.saveOverlay = true;
        dispatch('member/addContact', {
          sMemberNumber: this.memberNumber,
          sFirstName: this.firstName,
          sLastName: this.lastName,
          sRelationship: this.relationship,
          sDateOfBirth: this.unformattedDateOfBirth,
          sPhoneNumber: this.phoneNumber,
          sGender: this.gender,
          sTfbId: this.trackingId,
        })
          .then(() => {
            // Update was successful
            this.showPhoneAdd = false;

            // Clear the form values
            this.memberNumber = null;
            this.firstName = null;
            this.lastName = null;
            this.relationship = null;
            this.unformattedDateOfBirth = null;
            this.phoneNumber = null;
            this.gender = null;

            // Refresh the phone number list
            dispatch('member/getPhoneNumbers', { sMemberNumber: this.memberNumber, sTfbId: this.trackingId });
            this.saveOverlay = false;
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error);
            this.saveOverlay = false;
          });
      },
    },
    mounted() {
      if (!this.isMemberRole) {
        // Not the member, don't allow changes
        this.formDisabled = true;
      }
      this.showPhoneAdd = false;
    },
  };
</script>
<style lang="scss">
</style>
