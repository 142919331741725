<template>
  <div>
    <v-row v-if="locations !== null && locations.length > 1">
      <v-col cols="12">
        <select-input rules="required"
                      label="Loss Location"
                      :items="locations"
                      item-text="DisplayAddress"
                      return-object
                      v-model="lossInformation.lossLocation"></select-input>
      </v-col>
    </v-row>
    <div v-if="(lossInformation.lossLocation && lossInformation.lossLocation.DisplayAddress && lossInformation.lossLocation.DisplayAddress === 'Other') || locations === null || locations.length <= 1">
      <v-row>
        <v-col cols="12">
          <text-input rules="required|min:3|max:40"
                      minlength="3"
                      maxlength="40"
                      autocomplete="off"
                      label="Loss Location"
                      v-model="lossInformation.location"
                      vid="lossLocation"></text-input>
        </v-col>
      </v-row>
      <!-- Loss City -->
      <v-row>
        <v-col cols="12">
          <text-input rules="required|min:3|max:25"
                      minlength="3"
                      maxlength="25"
                      autocapitalize="words"
                      autocomplete="shipping address-level2"
                      label="City"
                      v-model="lossInformation.city"
                      vid="lossCity"></text-input>
        </v-col>
      </v-row>
      <!-- County -->
      <v-row>
        <v-col cols="12">
          <select-input rules="required"
                        label="County"
                        :items="countyList"
                        item-text="CountyName"
                        item-value="CountyName"
                        v-model="lossInformation.county"
                        vid="lossCounty"></select-input>
        </v-col>
      </v-row>
      <!-- State -->
      <v-row>
        <v-col cols="12">
          <select-input rules="required|alpha|min:2|max:2"
                        label="State"
                        :items="stateList"
                        item-text="StateName"
                        item-value="StateCode"
                        v-model="lossInformation.state"
                        vid="lossState"></select-input>
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'LossLocation',
    props: {
      lossInformation: {
        type: Object,
        required: true,
      },
      locations: {
        type: Array,
        required: true,
      },
      stateList: {
        type: Object,
        required: true,
      },
      countyList: {
        type: Object,
        required: true,
      },
    },
  };
</script>
<style lang="scss">

</style>
