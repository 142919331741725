<template>
  <div>
    <!-- Other Party Driver Information -->
    <v-row>
      <v-col cols="12">
        <span class="tfb-title-text-smaller text-darkest-blue text-uppercase">
          Other Party Driver Information
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required|name|min:2|max:25"
                    minlength="2"
                    maxlength="25"
                    label="Driver First Name"
                    v-model="thirdParty.driver.name.firstName"
                    autocomplete="off"
                    autocapitalize="words"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required|name|min:2|max:25"
                    minlength="2"
                    maxlength="25"
                    label="Driver Last Name"
                    v-model="thirdParty.driver.name.lastName"
                    autocomplete="off"
                    autocapitalize="words"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="min:2|max:250"
                    minlength="2"
                    maxlength="250"
                    label="Street Address"
                    v-model="thirdParty.driver.address.addressLine1"
                    autocomplete="off"
                    autocapitalize="words"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="min:2|max:250"
                    minlength="2"
                    maxlength="250"
                    label="City"
                    v-model="thirdParty.driver.address.city"
                    autocomplete="off"
                    autocapitalize="words"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="alpha|min:2|max:2"
                      label="State"
                      :items="stateList"
                      item-text="StateName"
                      item-value="StateCode"
                      v-model="thirdParty.driver.address.state"
                      vid="addState"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="numeric|min:5|max:5"
                    minlength="5"
                    maxlength="5"
                    label="ZIP Code"
                    v-model="thirdParty.driver.address.zip"
                    type="number"
                    autocomplete="shipping postal-code"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required_if:thirdType|phone_number|min:10|max:12"
                    autocomplete="home tel"
                    minlength="10"
                    maxlength="12"
                    label="Phone Number"
                    v-model="thirdParty.driver.phoneNumber"
                    v-mask="'###-###-####'"
                    type="tel"
                    vid="thirdPhone"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required_if:thirdPhone"
                      label="Phone Number Type"
                      :items="phoneTypes"
                      item-text="usageTypeName"
                      item-value="usageTypeCode"
                      v-model="thirdParty.driver.phoneType"
                      vid="thirdType"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required_if:dlState|min:4|max:13"
                    label="Driver License Number"
                    autocapitalize="characters"
                    minlength="4"
                    maxlength="13"
                    v-model="thirdParty.driver.licenseNumber"
                    vid="dlNum"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required_if:dlNum|alpha|min:2|max:2"
                      label="Driver License State"
                      :items="stateList"
                      item-text="StateName"
                      item-value="StateCode"
                      v-model="thirdParty.driver.licenseState"
                      vid="dlState"></select-input>
      </v-col>
    </v-row>
    <!-- Other Party Vehicle Information -->
    <v-row>
      <v-col cols="12">
        <span class="tfb-title-text-smaller text-darkest-blue text-uppercase">
          Other Party Vehicle Information
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Vehicle Year"
                      :items="vehicleYears"
                      item-text="Value"
                      item-value="Key"
                      v-model="thirdParty.vehicle.year"
                      @change="getVehicleMakes()"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Vehicle Make"
                      :items="vehicleMakes"
                      item-text="Value"
                      item-value="Key"
                      v-model="thirdParty.vehicle.make"
                      @change="getVehicleModels()"
                      :disabled="makeSelectionDisabled"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required"
                      label="Vehicle Model"
                      :items="vehicleModels"
                      item-text="Value"
                      item-value="Key"
                      v-model="thirdParty.vehicle.model"
                      :disabled="modelSelectionDisabled"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required_if:plateState"
                    label="License Plate Number"
                    autocapitalize="characters"
                    v-model="thirdParty.vehicle.licensePlate"
                    vid="plateNum"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <select-input rules="required_if:plateNum|alpha|min:2|max:2"
                      label="License Plate State"
                      :items="stateList"
                      item-text="StateName"
                      item-value="StateCode"
                      v-model="thirdParty.vehicle.licensePlateState"
                      vid="plateState"></select-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required_if:ownerLast|name|min:2|max:25"
                    label="Owner First Name (If Different Than Driver)"
                    autocapitalize="words"
                    autocomplete="off"
                    minlength="2"
                    maxlength="25"
                    v-model="thirdParty.vehicle.ownerInformation.firstName"
                    vid="ownerFirst"></text-input>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <text-input rules="required_if:ownerFirst|name|min:2|max:25"
                    label="Owner Last Name (If Different Than Driver)"
                    autocapitalize="words"
                    autocomplete="off"
                    minlength="2"
                    maxlength="25"
                    v-model="thirdParty.vehicle.ownerInformation.lastName"
                    vid="ownerLast"></text-input>
      </v-col>
    </v-row>
    <v-row v-if="thirdParty.vehicle">
      <v-col cols="12"
             md="8">
        <label>
          Did the airbags deploy?
        </label>
      </v-col>
      <v-col cols="12"
             md="4">
        <radio-group-input rules="required"
                           name="vehicle airbags"
                           :options="yesNoOptions"
                           v-model="thirdParty.vehicle.wereAirbagsDeployed"
                           vid="airbags"></radio-group-input>
      </v-col>
    </v-row>
    <v-row v-if="thirdParty.vehicle">
      <v-col cols="12"
             md="8">
        <label>
          Is the vehicle driveable?
        </label>
      </v-col>
      <v-col cols="12"
             md="4">
        <radio-group-input rules="required"
                           name="vehicle driveable"
                           :options="yesNoOptions"
                           v-model="thirdParty.vehicle.isDriveable"
                           vid="driveable"></radio-group-input>
      </v-col>
    </v-row>
    <v-row v-if="thirdParty.vehicle && thirdParty.vehicle.isDriveable && thirdParty.vehicle.isDriveable === 'N'">
      <v-col cols="12">
        <text-input rules="required|min:3|max:40"
                    minlength="3"
                    maxlength="40"
                    autocomplete="off"
                    counter="40"
                    label="Current Location of Vehicle"
                    v-model="thirdParty.vehicle.currentLocation"></text-input>
      </v-col>
    </v-row>
    <v-row v-if="thirdParty.driver">
      <v-col cols="12"
             md="8">
        <label>
          Was anyone in this vehicle injured?
        </label>
      </v-col>
      <v-col cols="12"
             md="4">
        <radio-group-input rules="required"
                           name="occupant injuries"
                           :options="yesNoOptions"
                           v-model="thirdParty.driver.injuryInd"
                           vid="injuries"></radio-group-input>
      </v-col>
    </v-row>
    <v-row v-if="thirdParty.driver && thirdParty.driver.injuryInd && thirdParty.driver.injuryInd === 'Y'">
      <v-col>
        <text-area-input rules="required|all_allowed|min:3|max:50"
                         label="Describe Injuries"
                         autocapitalize="sentences"
                         autocomplete="off"
                         minlength="3"
                         maxlength="50"
                         :counter="50"
                         v-model="thirdParty.driver.injuryDescription"></text-area-input>
      </v-col>
    </v-row>
  </div>
</template>
<script>
  import { dispatch } from 'vuex-pathify';
  import { mask } from 'vue-the-mask';
  import tfbHelperMixin from '@/mixins/helpers';
/* eslint object-shorthand: 0 */

  export default {
    name: 'ClaimThirdParty',
    props: {
      thirdParty: {
        type: Object,
        required: true,
      },
    },
    mixins: [tfbHelperMixin],
    directives: { mask },
    data() {
      return {
        stateList: [],
        phoneTypes: [],
        vehicleYears: [],
        vehicleMakes: [],
        vehicleModels: [],
        yearSelectionDisabled: true,
        makeSelectionDisabled: true,
        modelSelectionDisabled: true,
        yesNoOptions: [{ Id: 0, DisplayText: 'Yes', Value: 'Y' }, { Id: 1, DisplayText: 'No', Value: 'N' }],
      };
    },
    methods: {
      getStateList() {
        // Get the state information
        dispatch('app/getStateList', { sTfbId: this.trackingId })
          .then((stateListResponse) => {
            if (stateListResponse && stateListResponse.length > 0) {
              this.stateList = stateListResponse;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getPhoneTypes() {
        // Get the phone type information
        dispatch('claims/getPhoneTypes')
          .then((phoneTypeList) => {
            if (phoneTypeList && phoneTypeList.length > 0) {
              this.phoneTypes = phoneTypeList;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getVehicleYears() {
        // Get the vehicle years information
        dispatch('claims/getVehicleYears')
          .then((vehicleYearResponse) => {
            if (vehicleYearResponse && vehicleYearResponse.length > 0) {
              this.vehicleYears = vehicleYearResponse;
            }
          })
          .catch((error) => {
            dispatch('app/setErrorMessage', error.toString());
          });
      },
      getVehicleMakes() {
        if (this.thirdParty.vehicle.year && this.thirdParty.vehicle.year !== '') {
          this.vehicleMakes = null;
          this.vehicleModels = null;
          this.$store.dispatch('app/clearErrorMessage');
          this.$store.dispatch('claims/getVehicleMakesByYear', { sYear: this.thirdParty.vehicle.year, sTfbId: this.trackingId })
            .then((makeListResponse) => {
              this.makeSelectionDisabled = false;
              this.vehicleMakes = makeListResponse;
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out
                this.$router.push({ name: 'claims' });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
            });
        }
      },
      getVehicleModels() {
        if (this.thirdParty.vehicle.make && this.thirdParty.vehicle.make !== '') {
          this.vehicleModels = null;
          this.$store.dispatch('app/clearErrorMessage');
          this.$store.dispatch('claims/getVehicleModelsByYearMake', { sYear: this.thirdParty.vehicle.year, sMake: this.thirdParty.vehicle.make, sTfbId: this.trackingId })
            .then((modelListResponse) => {
              this.modelSelectionDisabled = false;
              this.vehicleModels = modelListResponse;
            })
            .catch((error) => {
              if (error.toString().indexOf('status code 401') >= 0) {
                // Session has timed out
                this.$router.push({ name: 'claims' });
              }
              this.$store.dispatch('app/setErrorMessage', error.toString());
            });
        }
      },
    },
    watch: {
      'accident.AccidentDate'(val) {
        this.accidentDateFormatted = this.formatDate(val);
      },
    },
    mounted() {
      this.getStateList();
      this.getVehicleYears();
      this.getPhoneTypes();
    },
  };
</script>
<style lang="scss">

</style>
