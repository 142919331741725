/* eslint no-underscore-dangle: 0 */
/* eslint dot-notation: 0 */
import Vue from 'vue';
import { make } from 'vuex-pathify';
import axios from 'axios';
import { orderBy } from 'lodash';
import helpers from '../../mixins/helpers';
import { genericErrorMessage } from '../../mixins/errorMessages';

// Creates a custom ULS log object with the required properties.
function UlsObject() {
  this.message = null;
  this.file = null;
  this.line = null;
  this.client = null;
  this.tracker = null;
  this.team = null;
  this.originalFile = null;
}

// Creates a custom file log object with the required properties.
// eslint-disable-next-line no-unused-vars
function FileObject() {
  this.level = null;
  this.file = null;
  this.method = null;
  this.message = null;
  this.client = null;
  this.tracker = null;
  this.originalFile = null;
}

const functions = {
  s4: () => (Math.floor((1 + Math.random()) * 0x10000)
    .toString(16).substring(1)),
  getClientInfo() {
    let builtDetail = '';
    if (navigator.userAgentData) {
      const hints = ['architecture', 'model', 'platform', 'platformVersion', 'uaFullVersion', 'fullVersionList'];
      navigator.userAgentData.getHighEntropyValues(hints).then((ua) => {
        builtDetail += ua.platform || navigator.userAgentData.platform;

        if (ua.mobile) {
          builtDetail += `(${ua.model})`;
        } else {
          builtDetail += `(${ua.architecture})`;
        }
        builtDetail += ` ${ua.platformVersion}`;
        if (ua.fullVersionList.length > 0) {
          for (let v = 0; v < ua.fullVersionList.length; v += 1) {
            const versionDetail = ua.fullVersionList[v];
            if (versionDetail.brand !== '.Not/A)Brand') {
              builtDetail += `/${versionDetail.version} ${versionDetail.brand}`;
            }
          }
        } else if (ua.brands.length > 0) {
          for (let i = 0; i < ua.brands.length; i += 1) {
            const brandDetail = ua.brands[i];
            if (brandDetail.brand !== '.Not/A)Brand') {
              builtDetail += `/${brandDetail.version} ${brandDetail.brand}`;
            }
          }
        }
      });

      return `${builtDetail}`;
    }

    // eslint-disable-next-line prefer-destructuring
    if (navigator.userAgent) {
      return `${navigator.userAgent}`;
    }

    const browserName = navigator.appCodeName;
    const browserVersion = navigator.appVersion;
    return `name='${browserName}' version='${browserVersion}'`;
  },
  encodeXmlString(txt) {
    return txt.toString().trim()
      .replace(/</g, '[')
      .replace(/>/g, ']')
      // .replace(/'/g, '&apos;')
      // .replace(/"/g, '&quot;')
      .replace(/&/g, '&amp;');
  },
  encodeMessageString(txt) {
    return txt.toString().trim()
      .replace('<!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">', '')
      .replace('<?xml version="1.0" encoding="utf-8"?>', '')
      .replace('<style>BODY { color: #000000; background-color: white; font-family: Verdana; margin-left: 0px; margin-top: 0px; } '
      + '#content { margin-left: 30px; font-size: .70em; padding-bottom: 2em; } A:link { color: #336699; font-weight: bold; text-decoration: underline; } '
      + 'A:visited { color: #6699cc; font-weight: bold; text-decoration: underline; } A:active { color: #336699; font-weight: bold; text-decoration: underline; } '
      + '.heading1 { background-color: #003366; border-bottom: #336699 6px solid; color: #ffffff; font-family: Tahoma; font-size: 26px; font-weight: normal;'
      + 'margin: 0em 0em 10px -20px; padding-bottom: 8px; padding-left: 30px;padding-top: 16px;} pre { font-size:small; background-color: #e5e5cc; padding: 5px; '
      + 'font-family: Courier New; margin-top: 0px; border: 1px #f0f0e0 solid; white-space: pre-wrap; white-space: -pre-wrap; word-wrap: break-word; } '
      + 'table { border-collapse: collapse; border-spacing: 0px; font-family: Verdana;} table th { border-right: 2px white solid; border-bottom: 2px white solid; '
      + 'font-weight: bold; background-color: #cecf9c;} table td { border-right: 2px white solid; border-bottom: 2px white solid; background-color: #e5e5cc;}</style>', '')
      .replace(/(\s\s)+/gim, '')
      .replace(/(<html xmlns="http:\/\/www.w3.org\/1999\/xhtml">|<\/html>)/gim, '')
      .replace(/(<head>|<\/head>)+/gim, '')
      .replace(/(<title>|<\/title>)+/gim, '')
      .replace(/(<body>|<\/body>)+/gim, '')
      .replace(/(<p>|<p class="heading1">|<p xmlns="">|<div>|<div id="content">)+/gim, '')
      .replace(/(<\/p>|<\/div)+/gim, '<br>')
      .replace(/[\n\r]+/gim, '<br>')
      // eslint-disable-next-line no-control-regex
      .replace(/[\r\n\x0B\x0C\u0085\u2028\u2029]+/gim, '<br>')
      .replace(/\r\n|\r|\n+/gim, '<br>')
      .replace(/[\t]+/gim, '-')
      .replace(/</g, '[')
      .replace(/>/g, ']');
    // .replace( /'/g, "&apos;" )
    // .replace( /"/g, "&quot;" )
    // .replace( /&/g, "&amp;" );
    // encodedText = sanitizeString( encodedText );

    // return encodedText;
  },
  generateErrorPacket(ulsObj) {
    return `<?xml version="1.0" encoding="utf-8"?><soap:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soap="http://schemas.xmlsoap.org/soap/envelope/">
    <soap:Body><SendClientScriptErrorReport xmlns="http://schemas.microsoft.com/sharepoint/diagnostics/">
      <message>${this.encodeMessageString(ulsObj.message)}</message>
      <file>${this.encodeXmlString(ulsObj.file)}</file>
      <line>${ulsObj.line}</line>
      <stack>${this.encodeXmlString(ulsObj.stack)}</stack>
      <client>${this.encodeXmlString(ulsObj.client)}</client>
      <team>${this.encodeXmlString(ulsObj.team)}</team>
      <originalFile>${this.encodeXmlString(ulsObj.originalFile)}</originalFile>
    </SendClientScriptErrorReport></soap:Body></soap:Envelope>`;
  },
  countMessageChunks(logMessage) {
    if (!logMessage) return 0;
    if (logMessage === null) return 0;
    if (logMessage.length <= 1000) return 1;
    return Math.floor((logMessage.length / 1000)) + 1;
  },
  logMessageClear(logMessage, startIdx) {
    if (!logMessage) return '';
    if (logMessage === null) return '';
    if (logMessage.length <= 1000) return logMessage;

    // console.log(`startIdx:${startIdx}`);
    // console.log(logMessage.substring(startIdx, (startIdx + 1000)));

    return logMessage.substring(startIdx, (startIdx + 1000));
  },
};

const sharePointListUrl = `${process.env.VUE_APP_ROOT_URI}/_api/lists/getbytitle('%%LIST%%')/items`;
const sharePointSearchUrl = `${process.env.VUE_APP_ROOT_URI}/_api/search/query`;
const mailServiceUrl = `${process.env.VUE_APP_WEB_URI}/services/TFBIC.Services.RESTSendEmail/`;
const popupIndicatorName = process.env.VUE_APP_WEB_ENV !== 'PROD' ? `tfb_${process.env.VUE_APP_WEB_ENV}_popup` : 'tfb_popup';

const config = {
  crossdomain: true,
  // withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose' },
};
const mailConfig = {
  crossdomain: true,
  // withCredentials: true,
  headers: { 'Access-Control-Allow-Origin': '*', Accept: 'application/json; odata=verbose', 'Content-Type': 'application/json; odata=verbose' },
};

function searchTransform(searchResults, rowCount, context, tfbId) {
  const returnSearch = {
    TotalRows: rowCount,
    Results: [],
  };
  try {
    // Get the results from the Query Rules
    if (searchResults.SecondaryQueryResults
      && searchResults.SecondaryQueryResults.results
      && searchResults.SecondaryQueryResults.results.length > 0
      && searchResults.SecondaryQueryResults.results[0].SpecialTermResults
      && searchResults.SecondaryQueryResults.results[0].SpecialTermResults.Results
      && searchResults.SecondaryQueryResults.results[0].SpecialTermResults.Results.results
      && searchResults.SecondaryQueryResults.results[0].SpecialTermResults.Results.results.length > 0) {
      for (let i = 0; i < searchResults.SecondaryQueryResults.results[0].SpecialTermResults.Results.results.length; i += 1) {
        const result = searchResults.SecondaryQueryResults.results[0].SpecialTermResults.Results.results[i];

        returnSearch.Results.push({
          Id: i + 1,
          Title: result.Title,
          Path: result.Url,
          Highlighted: result.Description,
          Rank: ((i + 1) * 100),
          Photo: null,
        });
      }
    }

    // Get the results from the search
    if (searchResults.PrimaryQueryResult
      && searchResults.PrimaryQueryResult.RelevantResults
      && searchResults.PrimaryQueryResult.RelevantResults.Table
      && searchResults.PrimaryQueryResult.RelevantResults.Table.Rows
      && searchResults.PrimaryQueryResult.RelevantResults.Table.Rows.results
      && searchResults.PrimaryQueryResult.RelevantResults.Table.Rows.results.length > 0) {
      for (let i = 0; i < searchResults.PrimaryQueryResult.RelevantResults.Table.Rows.results.length; i += 1) {
        const result = searchResults.PrimaryQueryResult.RelevantResults.Table.Rows.results[i];
        if (result.Cells && result.Cells.results && result.Cells.results.length > 0) {
          // 0: Rank,1: DocId,2: Title,3: BlogCategoryOWSCHCS,4: BlogSubCategoryOWSCHCS,5: BlogUrlCategoryOWSCHCS,6: BlogSlugOWSTEXT,
          // 7: BlurbOWSTEXT, 8: CountyIdOWSNMBR, 9: OfficeBranchCodeOWSCHCS, 10: ServiceTypeOWSTEXT, 11: AgentId, 12: FAQAnswerOWSHTML,
          // 13: HitHighlightedSummary, 14: Path, 15: OriginalPath, 16: PartitionId, 17: UrlZone, 18: Culture, 19: ResultTypeId, 20: RenderTemplateId
          if (!helpers.methods.containsString(result.Cells.results[14].Value, 'sitemap') && !helpers.methods.containsString(result.Cells.results[14].Value.toLowerCase(), 'allitems.aspx')) {
            let updatedTitle = result.Cells.results[2].Value;
            let updatedPhoto = null;
            let updatedPath = process.env.VUE_APP_ROOT_URI;
            let updatedHighlighted = helpers.methods.replaceAll(result.Cells.results[13].Value, '<ddd/>', '...');
            updatedHighlighted = helpers.methods.replaceAll(updatedHighlighted, '<c0>', '<strong>');
            updatedHighlighted = helpers.methods.replaceAll(updatedHighlighted, '</c0>', '</strong>');

            if (result.Cells.results[6].Value !== null) {
              // Blog entry
              updatedPath += '/blog';

              if (result.Cells.results[5].Value !== null) {
                const cleanUrlCategoryName = helpers.methods.replaceAll(helpers.methods.replaceAll(result.Cells.results[5].Value, ' and ', '-'), ' ', '-').toLowerCase();
                updatedPath += `/${cleanUrlCategoryName}/${result.Cells.results[6].Value.toLowerCase()}`;
              } else if (result.Cells.results[3].Value !== null) {
                const cleanCategoryName = helpers.methods.replaceAll(helpers.methods.replaceAll(result.Cells.results[3].Value, ' and ', '-'), ' ', '-').toLowerCase();
                updatedPath += `/${cleanCategoryName}`;

                if (result.Cells.results[4].Value !== null) {
                  const cleanSubCategoryName = helpers.methods.replaceAll(helpers.methods.replaceAll(result.Cells.results[4].Value, ' and ', '-'), ' ', '-').toLowerCase();
                  updatedPath += `/${cleanSubCategoryName}/${result.Cells.results[6].Value.toLowerCase()}`;
                } else {
                  updatedPath += `/${result.Cells.results[6].Value.toLowerCase()}`;
                }
              }

              if (updatedHighlighted === null || updatedHighlighted === '') {
                if (result.Cells.results[7].Value !== null) {
                  updatedHighlighted = result.Cells.results[7].Value;
                }
              }
            } else if (result.Cells.results[10].Value !== null) {
              // Service entry
              updatedPath += `/insurance/${result.Cells.results[10].Value}`;
            } else if (result.Cells.results[8].Value !== null) {
              // County entry
              updatedTitle += ' County Office';
              const cleanCountyName = helpers.methods.replaceAll(result.Cells.results[2].Value, ' ', '-').toLowerCase();
              updatedPath += `/county/details/${cleanCountyName}`;

              if (result.Cells.results[9].Value !== null) {
                // Branch entry
                updatedTitle += ` - ${result.Cells.results[9].Value.toLowerCase()}`;
                updatedPath += `/${result.Cells.results[9].Value.toLowerCase()}`;
              }
            } else if (result.Cells.results[11].Value !== null) {
              // Agent entry
              updatedPath += `/insurance/agent/${result.Cells.results[11].Value}`;
              updatedPhoto = `${process.env.VUE_APP_ROOT_URI}/agentphotos/${result.Cells.results[11].Value}.jpg`;
            } else if (result.Cells.results[12].Value !== null) {
              // FAQ entry
              updatedPath = null;

              updatedHighlighted = result.Cells.results[12].Value.replace(/(http&#58;\/\/tfbtel\.)/igm, 'tel:').replace(/(http:\/\/tfbtel\.)/igm, 'tel:');
              if (updatedHighlighted.indexOf('\n\n') >= 0) {
                updatedHighlighted = updatedHighlighted.substring(0, updatedHighlighted.indexOf('\n\n'));
              }
            } else if (result.Cells.results[2].Value !== null && result.Cells.results[2].Value.length === 5) {
              // Agent entry
              updatedPath += `/insurance/agent/${result.Cells.results[2].Value}`;
              updatedPhoto = `${process.env.VUE_APP_ROOT_URI}/agentphotos/${result.Cells.results[2].Value}.jpg`;
            } else if (result.Cells.results[14].Value !== null) {
              // Something else
              updatedPath = result.Cells.results[14].Value;
            }

            returnSearch.Results.push({
              Id: result.Cells.results[1].Value,
              Title: updatedTitle,
              Path: updatedPath,
              Highlighted: updatedHighlighted,
              Rank: result.Cells.results[0].Value,
              Photo: updatedPhoto,
            });
          }
        }
      }
    }

    if (returnSearch.Results && returnSearch.Results !== null && returnSearch.Results.length > 0) {
      const sortedResults = orderBy(returnSearch.Results, ['Rank'], ['desc']);
      returnSearch.Results = sortedResults;
    }
  } catch (error) {
    context.dispatch('app/logToULS', {
      logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'searchTransform', trackerId: tfbId,
    }, { root: true });
    // return searchResults;
  }
  return returnSearch;
}

function parseErrorMessage(errorText) {
  let outputErrorText = genericErrorMessage;
  try {
    if (errorText && errorText !== null) {
      if (errorText.ErrorMessage) {
        outputErrorText = errorText.ErrorMessage;
      } else if (errorText.errorMessage) {
        outputErrorText = errorText.errorMessage;
      } else if (errorText._errorMessage) {
        outputErrorText = errorText._errorMessage;
      } else if (errorText.Error) {
        outputErrorText = errorText.Error;
      } else if (errorText.message) {
        outputErrorText = errorText.message;
      } else if (errorText !== '') {
        if (errorText.indexOf('status code 401') >= 0) {
          // return sessionErrorMessage;
          return genericErrorMessage;
        }
        if (errorText.indexOf('status code 400') >= 0 || errorText.indexOf('status code 500') >= 0) {
          return genericErrorMessage;
        }
        if (errorText.indexOf('invalid_grant') >= 0) {
          // return sessionErrorMessage;
          return 'Username/Password Not Found';
        }
        outputErrorText = errorText;
      }
    } else {
      outputErrorText = genericErrorMessage;
    }
  } catch (error) {
    // Do nothing
  }
  return outputErrorText.replace('user id', 'email address').replace('Error: ', '').replace('Network Error', genericErrorMessage);
}

const mutations = {
  SET_ACCESS_PAGE(state, accessPage) {
    state.accessPage = accessPage;
  },
  SET_IS_LOADING(state, isLoading) {
    state.isLoading = isLoading;
  },
  SET_IS_DOCUMENT_LOADING(state, isDocumentLoading) {
    state.isDocumentLoading = isDocumentLoading;
  },
  SET_ERROR_MESSAGE(state, errorMessage) {
    state.errorMessage = errorMessage;
  },
  SET_TFB_ID(state) {
    state.tfbId = `${functions.s4()}${functions.s4()}-${functions.s4()}-${functions.s4()}-${functions.s4()}-${functions.s4()}${functions.s4()}${functions.s4()}`;
  },
  SET_JD_POWER(state, value) {
    state.showJdPower = value;
  },
  SET_DRAWER(state, value) {
    state.drawer = value;
  },
  SET_MODAL(state, value) {
    state.modal = value;
  },
  SET_TEXT_ELIGIBLE(state, value) {
    state.textEligible = value;
  },
  SET_SEARCH_VISIBLE(state, value) {
    state.searchVisible = value;
  },
  SET_LENDERDOCK(state, value) {
    state.lenderdock = value;
  },
  SET_QUOTE_AGENT_CODE(state, value) {
    state.quoteAgentCode = value;
  },
  SET_REGISTERED_FISERV_PAYMENT_URL(state, value) {
    state.registeredFiservPaymentUrl = value;
  },
  SET_NON_REGISTERED_FISERV_PAYMENT_URL(state, value) {
    state.nonRegisteredFiservPaymentUrl = value;
  },
  SET_MEMBERSHIP_PAYMENTS_URL(state, value) {
    state.membershipPaymentsUrl = value;
  },
  SET_NON_REGISTERED_INVOICE_CLOUD_PAYMENT_URL(state, value) {
    state.nonRegisteredInvoiceCloudPaymentUrl = value;
  },
  SET_INVOICE_CLOUD_IFRAME_URL(state, value) {
    state.invoiceCloudIFrameUrl = value;
  },
  SET_CLAIM_POINT_URL(state, value) {
    state.claimPointUrl = value;
  },
  SET_APPLE_STORE_URL(state, value) {
    state.appleAppStoreLink = value;
  },
  SET_GOOGLE_STORE_URL(state, value) {
    state.googleAppStoreLink = value;
  },
  SET_PAYMENT_WORDING_DISPLAY(state, value) {
    state.paymentWordingDisplay = value;
  },
  SET_LIVE_CHAT_ENABLED(state, value) {
    state.liveChatEnabled = value;
  },
  SET_DRIVE_N_SAVE_ENABLED(state, value) {
    state.driveNSaveEnabled = value;
  },
  SET_AGERO_ROADSIDE_ENABLED(state, value) {
    state.ageroRoadsideEnabled = value;
  },
  SET_CLAIM_POINT_ENABLED(state, value) {
    state.claimPointEnabled = value;
  },
  SET_PH_MOBILE_APP_DISPLAY(state, value) {
    state.policyholderMobileAppDisplay = value;
  },
  SET_MOBILE_APP_DISPLAY(state, value) {
    state.mobileAppDisplay = value;
  },
  SET_GW_PAYMENTS_ENABLED(state, value) {
    state.guidewirePaymentsEnabled = value;
  },
  SET_LOGIN_CAPTCHA_ENABLED(state, value) {
    state.loginCaptchaEnabled = value;
  },
  SET_CONTACT_UPDATE_ENABLED(state, value) {
    state.contactUpdateEnabled = value;
  },
  SET_PHONE_VERIFICATION_ENABLED(state, value) {
    state.phoneVerificationEnabled = value;
  },
};

const state = {
  drawer: null,
  modal: null,
  isLoading: false,
  isDocumentLoading: false,
  tfbId: null,
  showJdPower: false,
  errorMessage: '',
  accessPage: null,
  textEligible: null,
  ebillEligible: null,
  lenderdock: false,
  quoteAgentCode: '',
  registeredFiservPaymentUrl: null,
  nonRegisteredFiservPaymentUrl: null,
  claimPointUrl: null,
  paymentWordingDisplay: 'N',
  searchVisible: false,
  liveChatEnabled: 'N',
  driveNSaveEnabled: 'N',
  ageroRoadsideEnabled: 'N',
  claimPointEnabled: 'N',
  mobileAppDisplay: 'N',
  policyholderMobileAppDisplay: 'N',
  guidewirePaymentsEnabled: 'N',
  loginCaptchaEnabled: 'N',
  contactUpdateEnabled: 'N',
  phoneVerificationEnabled: 'N',
  appleAppStoreLink: null,
  googleAppStoreLink: null,
  nonRegisteredInvoiceCloudPaymentUrl: null,
  invoiceCloudIFrameUrl: null,
  membershipPaymentsUrl: null,
};

const actions = {
  async setNonRegisteredFiservPaymentsUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'NonRegisteredPaymentsEncrypted' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_NON_REGISTERED_FISERV_PAYMENT_URL', response);
        }
      });
  },
  async setRegisteredFiservPaymentsUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'RegisteredPaymentsEncrypted' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_REGISTERED_FISERV_PAYMENT_URL', response);
        }
      });
  },
  async setInvoiceCloudMembershipPaymentsUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'InvoiceCloudMembershipPayments' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_MEMBERSHIP_PAYMENTS_URL', response);
        }
      });
  },
  async setMembershipPaymentsUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'MembershipPayments' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_MEMBERSHIP_PAYMENTS_URL', response);
        }
      });
  },
  async setNonRegisteredInvoiceCloudPaymentUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'NonRegisteredInvoiceCloudPaymentUrl' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_NON_REGISTERED_INVOICE_CLOUD_PAYMENT_URL', response);
        }
      });
  },
  async setInvoiceCloudIFrameUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'InvoiceCloudIFrameUrl' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_INVOICE_CLOUD_IFRAME_URL', response);
        }
      });
  },
  async setClaimPointUrl(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'ClaimPointUrl' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_CLAIM_POINT_URL', response);
        }
      });
  },
  async setAppleAppStoreLink(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'AppleAppStoreAppLink' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_APPLE_STORE_URL', response);
        }
      });
  },
  async setGooglePlayStoreLink(context) {
    await context.dispatch('getConfiguration', { sSection: 'ExternalUrl', sTitle: 'GooglePlayStoreAppLink' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_GOOGLE_STORE_URL', response);
        }
      });
  },
  // START: Feature Flags
  async setJdPower(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'JDPower' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_JD_POWER', (response === 'Y'));
        }
      });
  },
  async setPaymentWordingDisplay(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'PaymentWordingDisplay' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_PAYMENT_WORDING_DISPLAY', response);
        }
      });
  },
  async setLiveChatEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'LiveChatEnabled' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_LIVE_CHAT_ENABLED', response);
        }
      });
  },
  async setDriveNSaveEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'DriveNSaveEnabled ' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_DRIVE_N_SAVE_ENABLED', response);
        }
      });
  },
  async setAgeroRoadsideEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'AgeroRoadsideEnabled' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_AGERO_ROADSIDE_ENABLED', response);
        }
      });
  },
  async setClaimPointEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'ClaimPointDisplay' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_CLAIM_POINT_ENABLED', response);
        }
      });
  },
  async setLoginCaptchaEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'LoginCaptchaEnabled' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_LOGIN_CAPTCHA_ENABLED', response);
        }
      });
  },
  async setContactUpdateEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'ContactUpdateEnabled' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_CONTACT_UPDATE_ENABLED', response);
        }
      });
  },
  async setPhoneVerificationEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'PhoneVerificationEnabled' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_PHONE_VERIFICATION_ENABLED', response);
        }
      });
  },
  async setPHMobileAppBannerEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'PHMobileAppBannerDisplay' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_PH_MOBILE_APP_DISPLAY', response);
        }
      });
  },
  async setMobileAppBannerEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'MobileAppBannerDisplay' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_MOBILE_APP_DISPLAY', response);
        }
      });
  },
  async setGuidewirePaymentsEnabled(context) {
    await context.dispatch('getFeatureFlag', { sTitle: 'GuidewirePaymentsEnabled' })
      .then((response) => {
        if (response && response !== '') {
          context.commit('SET_GW_PAYMENTS_ENABLED', response);
        }
      });
  },
  checkMobileAppDisplay(context) {
    const popupSession = sessionStorage.getItem(popupIndicatorName);
    const popupCookie = Vue.$cookies.get(popupIndicatorName);

    // console.log(popupSession);
    // console.log(popupCookie);

    // Is information populated in the SessionStorage?
    if (popupSession !== undefined && popupSession !== null && popupSession === 'true') {
      context.commit('SET_MOBILE_APP_DISPLAY', 'N');
    }

    // Is information populated in the Cookie?
    if (popupCookie !== undefined && popupCookie !== null && popupCookie === 'true') {
      context.commit('SET_MOBILE_APP_DISPLAY', 'N');
    } else {
      context.dispatch('setMobileAppBannerEnabled');
    }
  },
  async setMobileAppDisplay(context) {
    Vue.$cookies.set(popupIndicatorName, 'true', '3d');
    sessionStorage.setItem(popupIndicatorName, 'true');
    context.commit('SET_MOBILE_APP_DISPLAY', 'N');
  },
  // END: Feature Flags
  setAccessPage(context, accessPage) {
    context.commit('SET_ACCESS_PAGE', accessPage);
  },
  async doApplicationCheck(context, sRequest) {
    const currentTimestamp = new Date().toISOString();
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'applicationmaintenance')}?$select=*&$filter=Title eq '${sRequest.sApplicationName}'`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0
            && response.data.d.results[0].StartTime <= currentTimestamp
            && response.data.d.results[0].EndTime > currentTimestamp) {
            resolve(response.data.d.results[0].MaintenanceMessage);
          }

          resolve('');
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'doApplicationCheck-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  async setVisitor(context) {
    context.commit('SET_TFB_ID');
    context.dispatch('setNonRegisteredFiservPaymentsUrl');
    context.dispatch('setRegisteredFiservPaymentsUrl');
    context.dispatch('setClaimPointUrl');
    context.dispatch('setAppleAppStoreLink');
    context.dispatch('setGooglePlayStoreLink');
    context.dispatch('setNonRegisteredInvoiceCloudPaymentUrl');
    context.dispatch('setInvoiceCloudIFrameUrl');
  // START: Feature Flags
    context.dispatch('setPaymentWordingDisplay');
    context.dispatch('setLiveChatEnabled');
    context.dispatch('setDriveNSaveEnabled');
    context.dispatch('setAgeroRoadsideEnabled');
    context.dispatch('setClaimPointEnabled');
    context.dispatch('checkMobileAppDisplay');
    context.dispatch('setPHMobileAppBannerEnabled');
    context.dispatch('setLoginCaptchaEnabled');
    context.dispatch('setContactUpdateEnabled');
    context.dispatch('setPhoneVerificationEnabled');
    await context.dispatch('setGuidewirePaymentsEnabled')
      .then(() => {
        if (context.state.guidewirePaymentsEnabled === 'Y') {
          context.dispatch('setInvoiceCloudMembershipPaymentsUrl');
        } else {
          context.dispatch('setMembershipPaymentsUrl');
        }
      });
  // END: Feature Flags
    await context.dispatch('setJdPower')
      .then(() => {
        const parsed = JSON.stringify({
          tfbId: context.state.tfbId,
          showJdPower: context.state.showJdPower,
        });
        sessionStorage.setItem('tfb_visitor', parsed);
      });
  },
  setIsLoading(context, isLoading) {
    context.commit('SET_IS_LOADING', isLoading);
  },
  setIsDocumentLoading(context, isDocumentLoading) {
    context.commit('SET_IS_DOCUMENT_LOADING', isDocumentLoading);
  },
  setErrorMessage(context, errorMessage) {
    context.commit('SET_ERROR_MESSAGE', `<strong>Error:</strong> ${parseErrorMessage(errorMessage)}`);
  },
  setEbillEligible(context, ebillStatus) {
    context.commit('SET_EBILL_ELIGIBLE', ebillStatus);
  },
  setTextEligible(context, textStatus) {
    context.commit('SET_TEXT_ELIGIBLE', textStatus);
  },
  setQuoteAgentCode(context, agentCode) {
    context.commit('SET_QUOTE_AGENT_CODE', agentCode);
  },
  clearErrorMessage(context) {
    context.commit('SET_ERROR_MESSAGE', '');
  },
  getStateList() {
    return new Promise((resolve) => {
      resolve([{ StateCode: 'AL', StateName: 'Alabama' }, { StateCode: 'AK', StateName: 'Alaska' }, { StateCode: 'AZ', StateName: 'Arizona' }, { StateCode: 'AR', StateName: 'Arkansas' },
        { StateCode: 'CA', StateName: 'California' }, { StateCode: 'CO', StateName: 'Colorado' }, { StateCode: 'CT', StateName: 'Connecticut' }, { StateCode: 'DE', StateName: 'Delaware' },
        { StateCode: 'DC', StateName: 'District of Columbia' }, { StateCode: 'FL', StateName: 'Florida' }, { StateCode: 'GA', StateName: 'Georgia' }, { StateCode: 'HI', StateName: 'Hawaii' },
        { StateCode: 'ID', StateName: 'Idaho' }, { StateCode: 'IL', StateName: 'Illinois' }, { StateCode: 'IN', StateName: 'Indiana' }, { StateCode: 'IA', StateName: 'Iowa' },
        { StateCode: 'KS', StateName: 'Kansas' }, { StateCode: 'KY', StateName: 'Kentucky' }, { StateCode: 'LA', StateName: 'Louisiana' }, { StateCode: 'ME', StateName: 'Maine' },
        { StateCode: 'MD', StateName: 'Maryland' }, { StateCode: 'MA', StateName: 'Massachusetts' }, { StateCode: 'MI', StateName: 'Michigan' }, { StateCode: 'MN', StateName: 'Minnesota' },
        { StateCode: 'MS', StateName: 'Mississippi' }, { StateCode: 'MO', StateName: 'Missouri' }, { StateCode: 'MT', StateName: 'Montana' }, { StateCode: 'NE', StateName: 'Nebraska' },
        { StateCode: 'NV', StateName: 'Nevada' }, { StateCode: 'NH', StateName: 'New Hampshire' }, { StateCode: 'NJ', StateName: 'New Jersey' }, { StateCode: 'NM', StateName: 'New Mexico' },
        { StateCode: 'NY', StateName: 'New York' }, { StateCode: 'NC', StateName: 'North Carolina' }, { StateCode: 'ND', StateName: 'North Dakota' }, { StateCode: 'OH', StateName: 'Ohio' },
        { StateCode: 'OK', StateName: 'Oklahoma' }, { StateCode: 'OR', StateName: 'Oregon' }, { StateCode: 'PA', StateName: 'Pennsylvania' }, { StateCode: 'PR', StateName: 'Puerto Rico' },
        { StateCode: 'RI', StateName: 'Rhode Island' }, { StateCode: 'SC', StateName: 'South Carolina' }, { StateCode: 'SD', StateName: 'South Dakota' },
        { StateCode: 'TN', StateName: 'Tennessee' }, { StateCode: 'TX', StateName: 'Texas' }, { StateCode: 'UT', StateName: 'Utah' }, { StateCode: 'VT', StateName: 'Vermont' },
        { StateCode: 'VA', StateName: 'Virginia' }, { StateCode: 'WA', StateName: 'Washington' }, { StateCode: 'WV', StateName: 'West Virginia' }, { StateCode: 'WI', StateName: 'Wisconsin' },
        { StateCode: 'WY', StateName: 'Wyoming' }, { StateCode: 'AS', StateName: 'American Samoa' }, { StateCode: 'GU', StateName: 'Guam' }, { StateCode: 'MP', StateName: 'Northern Marina Islands' },
        { StateCode: 'PR', StateName: 'Puerto Rico' }, { StateCode: 'VI', StateName: 'US Virgin Islands' }, { StateCode: 'UM', StateName: 'US Minor Outlying Islands' },
        { StateCode: 'FM', StateName: 'Federated States of Micronesia' }, { StateCode: 'MH', StateName: 'Marshall Islands' }, { StateCode: 'PW', StateName: 'Palau' },
        { StateCode: 'AA', StateName: 'Armed Forces Americas' }, { StateCode: 'AE', StateName: 'Armed Forces Europe' }, { StateCode: 'AP', StateName: 'Armed Forces Pacific' }]);
    });
  },
  getSearchResults(context, sRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('logToULS', {
        logLevel: 'Info', logMessage: `Searching for: ${sRequest.sSearchTerm}, starting at ${sRequest.sStartRow}`, fileName: 'app.js', methodName: 'getSearchResults-Start', trackerId: sRequest.sTfbId,
      });

      axios
        .get(`${sharePointSearchUrl}?querytext='${sRequest.sSearchTerm}'&selectproperties='Title,BlogCategoryOWSCHCS,BlogSubCategoryOWSCHCS,BlogUrlCategoryOWSCHCS,BlogSlugOWSTEXT,BlurbOWSTEXT,CountyIdOWSNMBR,OfficeBranchCodeOWSCHCS,ServiceTypeOWSTEXT,AgentId,FAQAnswerOWSHTML,HitHighlightedSummary,Path'&startRow=${sRequest.sStartRow}&rowLimit=10&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'`, config)
        .then((response) => {
          if (response && response.data && response.data.d
            && response.data.d.query && response.data.d.query.PrimaryQueryResult
            && response.data.d.query.PrimaryQueryResult.RelevantResults
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results.length > 0) {
            resolve(searchTransform(response.data.d.query, response.data.d.query.PrimaryQueryResult.RelevantResults.TotalRows,
              context, sRequest.sTfbId));
          }

          resolve([]);
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'app.js', methodName: 'getSearchResults-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getBlogSearchResults(context, sRequest) {
    return new Promise((resolve, reject) => {
      context.dispatch('logToULS', {
        logLevel: 'Info', logMessage: `Searching blog for: ${sRequest.sSearchTerm}`, fileName: 'app.js', methodName: 'getBlogSearchResults-Start', trackerId: sRequest.sTfbId,
      });

      axios
        .get(`${sharePointSearchUrl}?querytext='${sRequest.sSearchTerm}+AND+(path:${process.env.VUE_APP_ROOT_URI}/lists/blog)'&selectproperties='Title,BlogCategoryOWSCHCS,BlogSubCategoryOWSCHCS,BlogUrlCategoryOWSCHCS,BlogSlugOWSTEXT,BlurbOWSTEXT,CountyIdOWSNMBR,OfficeBranchCodeOWSCHCS,ServiceTypeOWSTEXT,AgentId,FAQAnswerOWSHTML,HitHighlightedSummary,Path'&startRow=${sRequest.sStartRow}&rowLimit=10&QueryTemplatePropertiesUrl='spfile://webroot/queryparametertemplate.xml'`, config)
        .then((response) => {
          if (response && response.data && response.data.d
            && response.data.d.query && response.data.d.query.PrimaryQueryResult
            && response.data.d.query.PrimaryQueryResult.RelevantResults
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results
            && response.data.d.query.PrimaryQueryResult.RelevantResults.Table.Rows.results.length > 0) {
            resolve(searchTransform(response.data.d.query, response.data.d.query.PrimaryQueryResult.RelevantResults.TotalRows,
              context, sRequest.sTfbId));
          }

          resolve([]);
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'app.js', methodName: 'getBlogSearchResults-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getNotices(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'notice')}?$select=*`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results) {
            resolve(response.data.d.results);
          }

          resolve([]);
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'getNotices-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getAgentDetails(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'agentdetails')}?$select=*&$filter=AgentId eq '${sRequest.sAgentCode}'`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(response.data.d.results[0]);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'getAgentDetails-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getChatPilot(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'chatpilot')}?$select=*&$filter=AgentId eq '${sRequest.sAgentCode}'`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(response.data.d.results[0]);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'getChatPilot-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getConfiguration(context, sRequest) {
    // TODO: Store configuration values and fetch from store when able
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'websiteconfiguration')}?$select=ConfigValue&$filter=((Section eq '${sRequest.sSection}') and (Title eq '${sRequest.sTitle}'))`, config)
        .then((response) => {
          if (response && response.data && response.data.d
            && response.data.d.results && response.data.d.results.length > 0) {
            resolve(response.data.d.results[0].ConfigValue);
          }

          resolve('');
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'app.js', methodName: 'getConfiguration-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getFeatureFlag(context, sRequest) {
    // TODO: Store configuration values and fetch from store when able
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'featureflags')}?$select=Value&$filter=(Title eq '${sRequest.sTitle}')`, config)
        .then((response) => {
          if (response && response.data && response.data.d
            && response.data.d.results && response.data.d.results.length > 0) {
            resolve(response.data.d.results[0].Value);
          }

          resolve('N');
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'app.js', methodName: 'getFeatureFlag-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getCountyDetails(context, sRequest) {
    return new Promise((resolve, reject) => {
      let detailUrl = `${sharePointListUrl.replace('%%LIST%%', 'countydetails')}?$select=*&$filter=Title eq '${sRequest.sCountyName.replace('-', ' ')}'`;

      if (sRequest.sBranchCode && sRequest.sBranchCode !== null && sRequest.sBranchCode !== '') {
        detailUrl += ` and OfficeBranchCode eq '${sRequest.sBranchCode.toUpperCase()}'`;
      }

      axios
        .get(detailUrl, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            resolve(response.data.d.results[0]);
          } else {
            resolve(null);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'app.js', methodName: 'getCountyDetails-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  async getStaticDocuments(context, sRequest) {
    // const currentTimestamp = new Date().toISOString();
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'staticdocuments')}?$select=Title,File,ID,DocumentType&$filter=DocumentLoB eq '${sRequest.sLineOfBusiness}'
and DocumentStartDate le '${sRequest.sEffectiveDate}' and DocumentEndDate ge '${sRequest.sEffectiveDate}'`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            const staticFileReturn = [];
            for (let i = 0; i < response.data.d.results.length; i += 1) {
              const fileResult = response.data.d.results[i];

              if (fileResult !== null && fileResult.File !== null && fileResult.File.__deferred !== null && fileResult.File.__deferred.uri !== null) {
                context.dispatch('getStaticDocument', {
                  sFileUrl: fileResult.File.__deferred.uri.toLowerCase(), sTfbId: sRequest.sTfbId,
                })
                  .then((fileFetchResult) => {
                    staticFileReturn.push({
                      ID: fileResult.ID,
                      Title: fileResult.Title,
                      DocumentType: fileResult.DocumentType,
                      Url: fileFetchResult.ServerRelativeUrl.toLowerCase(),
                    });
                  })
                  .catch(() => {
                    // Do nothing
                  });
              }
            }
            resolve(staticFileReturn);
          }

          resolve('');
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'getStaticDocuments-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  async getStaticDocument(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(sRequest.sFileUrl, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d) {
            resolve(response.data.d);
          }

          resolve('');
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'getStaticDocument-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getVideosByPage(context, sRequest) {
    return new Promise((resolve, reject) => {
      const videoArray = [];
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'videos')}?$select=*&$filter=DisplayPage eq '${sRequest.sPage}' and TFBEnabled eq 1&$orderby=OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results && response.data.d.results.length > 0) {
            for (let e = 0; e < response.data.d.results.length; e += 1) {
              const videoEntry = response.data.d.results[e];

              if (videoEntry && videoEntry.ID) {
                 videoArray.push({
                   Id: videoEntry.ID,
                   Order: videoEntry.OrderBy,
                   SubTitle: videoEntry.SubTitle,
                   Title: videoEntry.Title,
                   Thumbnail: videoEntry.AlternateThumbnailUrl !== null ? videoEntry.AlternateThumbnailUrl.Url : '',
                 });
              }
            }
          }

          const sortedVideos = orderBy(videoArray, ['OrderBy'], ['asc']);
          resolve(sortedVideos);
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(error), fileName: 'app.js', methodName: 'getVideosByPage-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  getVideoById(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'videos')}(${sRequest.sId})/Properties`, config)
        .then((propertyResponse) => {
          if (propertyResponse && propertyResponse.data && propertyResponse.data.d) {
            if (propertyResponse.data.d.VideoSetDefaultEncoding) {
              // Get video link
              const videoUrl = propertyResponse.data.d.VideoSetDefaultEncoding.split(',');
              resolve({ Embed: null, Url: videoUrl[0] });
            } else if (propertyResponse.data.d.VideoSetEmbedCode) {
              // Get video link
              resolve({ Embed: propertyResponse.data.d.VideoSetEmbedCode, Url: null });
            } else {
              resolve({ Embed: null, Url: null });
            }
          }
        })
        .catch((propertyError) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: JSON.stringify(propertyError), fileName: 'app.js', methodName: 'getVideoById-Error', trackerId: sRequest.sTfbId,
          });
          reject(propertyError);
        });
    });
  },
  getFaqsByKeyword(context, sRequest) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${sharePointListUrl.replace('%%LIST%%', 'faqs')}?$select=ID,Title,FAQAnswer,FAQGoogleIndicator,OrderBy,TaxKeyword&$filter=TaxCatchAll/Term eq '
${sRequest.sKeyword}'&$orderby=OrderBy`, config)
        .then((response) => {
          if (response && response.data && response.data.d && response.data.d.results) {
            resolve(response.data.d.results);
          } else {
            resolve([]);
          }
        })
        .catch((error) => {
          context.dispatch('logToULS', {
            logLevel: 'Error', logMessage: error, fileName: 'app.js', methodName: 'getFaqsByKeyword-Error', trackerId: sRequest.sTfbId,
          });
          reject(error);
        });
    });
  },
  /**
   * logToULS
   *  Function to log error messages to Diagnostic web service.
   *  Modified From: https://msdn.microsoft.com/en-us/library/office/hh803115(v=office.14).aspx
   *  Used instead of ULSOnError/SP.ULS because it is inconsistent and only logs system/SharePoint
   *  generated errors
   * @param {string} logLevel - Level of importance (Verbose, Info, Warning, Error)
   * @param {string} logMessage - Message to log
   * @param {string} fileName - File which triggered log request
   * @param {string} methodName - Code method/group which triggered log request
   * @param {string} trackerId - Visitor tracking id
   * @returns {} - No return
   */
  // eslint-disable-next-line no-unused-vars
  logToULS(context, sRequest) {
    const ulsObj = new UlsObject();
    const logConfig = {
      crossdomain: true,
      // withCredentials: true,
      headers: {
        'Access-Control-Allow-Origin': '*', Accept: 'application/xml, text/xml, */*; odata=verbose', 'Content-Type': 'text/xml; charset=utf-8; odata=verbose',
      },
    };

    if (sRequest.logMessage && sRequest.logMessage !== null && sRequest.logMessage !== '') {
      const chunkCount = functions.countMessageChunks(sRequest.logMessage);
      // console.log(`chunkCount:${chunkCount}`);

      for (let i = 0; i < chunkCount; i += 1) {
        const messageIndex = i * 1000;
        const messageCounter = `(${i + 1} of ${chunkCount}) `;
        // Build ULS object
        ulsObj.stack = sRequest.trackerId || '0000000';
        ulsObj.team = sRequest.fileName || 'Unknown';
        ulsObj.file = sRequest.methodName || 'Unknown';
        ulsObj.line = 0;
        ulsObj.originalFile = window.location.href;

        // Create client information.
        ulsObj.client = functions.getClientInfo();
        ulsObj.message = `${chunkCount > 1 ? messageCounter : ''}${functions.logMessageClear(sRequest.logMessage, messageIndex)}`;

        if (ulsObj.message && ulsObj.message !== null && ulsObj.message !== '') {
          // Create the soap packet.
          const soapPacket = functions.generateErrorPacket(ulsObj);

          // Post to the web service.
          axios.post(`${process.env.VUE_APP_ROOT_URI}/_vti_bin/diagnostics.asmx`, soapPacket, logConfig);
          // console.log(soapPacket);
        }
      }
    }

    return true;
  },
  sendMail(context, sRequest) {
    const sendMailRequest = {
      BCC: sRequest.sBCC, Body: sRequest.sBody, CC: sRequest.sCC, From: sRequest.sFrom, Subject: sRequest.sSubject, To: sRequest.sTo, Token: sRequest.sToken,
    };

    return axios.post(`${mailServiceUrl}/mail`, sendMailRequest, mailConfig);
  },
};

const getters = {
  ...make.getters(state),
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
