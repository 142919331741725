<template>
  <v-row>
    <v-col cols="12">
      <h4 class="tfb-title-text-small-bold text-blue text-uppercase">{{claimType}} Claims</h4>
      <v-container v-if="claimListLoading">
        <v-row v-for="i in 3"
               :key="i">
          <v-col cols="12">
            <v-skeleton-loader :loading="claimListLoading"
                               type="list-item-avatar-three-line"
                               class="mx-auto">
            </v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
      <div v-if="!claimListLoading && claimList !== null && claimList.length > 0">
        <v-expansion-panels accordion
                            flat>
          <v-expansion-panel v-for="claim in claimList"
                             :key="claim.ClaimNumber">
            <v-expansion-panel-header>
              <v-container>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="font-weight-bold">{{claim.PolicyType}} Policy:</span> {{claim.PolicyNumber}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="font-weight-bold">Claim:</span> {{claim.ClaimNumber}}
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="font-weight-bold">Date of Loss:</span> {{formatDate(claim.LossDate)}}
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-container fluid>
                <v-row>
                  <v-col cols="12"
                         md="5">
                    <span class="font-weight-bold">Description of Loss</span><br />
                  </v-col>
                  <v-col cols="12"
                         md="7">
                    {{claim.LossDescription}}<br />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12">
                    <v-data-table dense
                                  :headers="coverageHeaders"
                                  :items="claim.Coverages"
                                  :items-per-page="10"
                                  class="tfb-table">
                      <template v-slot:no-data>
                        No Coverage Information Available
                      </template>
                    </v-data-table>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12"
                         class="py-0">
                    <span class="text-darkest-blue text-uppercase font-weight-bold">
                      Claims Adjuster
                    </span>
                  </v-col>
                </v-row>
                <v-row v-if="claim.AdjusterName && claim.AdjusterName !== null && claim.AdjusterName !== ''">
                  <v-col cols="12">
                    <address>
                      <span class="font-weight-bold">{{claim.AdjusterName}}</span><br />
                      <div v-if="claim.Address !== null && claim.Address !== ''">
                        <span v-html="claim.Address"></span>
                      </div>
                      <div v-if="claim.PhoneNumber !== null && claim.PhoneNumber !== ''">
                        <strong>Phone:</strong> <a v-bind:href="claim.PhoneNumber | linkFormatPhone">{{claim.PhoneNumber | formatPhone}}</a><br />
                      </div>
                      <div v-if="claim.EmailAddress !== null && claim.EmailAddress !== ''">
                        <strong>Email Address:</strong> <a v-bind:href="`mailto:${claim.EmailAddress}`">{{claim.EmailAddress}}</a><br />
                      </div>
                    </address>
                  </v-col>
                </v-row>
                <v-row v-else>
                  <v-col cols="12">
                    <span class="font-weight-bold">Adjuster Information Unavailable</span><br />
                  </v-col>
                </v-row>
              </v-container>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
      <v-container v-if="!claimListLoading && (!claimList || claimList.length <= 0)">
        <v-row>
          <v-col cols="12"
                 class="py-0">
            You have no available {{claimType}} claims to view.
          </v-col>
        </v-row>
      </v-container>
    </v-col>
  </v-row>
</template>
<script>
  import tfbHelperMixin from '@/mixins/helpers';
  import tfbDataMixin from '@/mixins/tfbData';

  export default {
    name: 'ViewClaims',
    props: {
      claimListLoading: {
        type: Boolean,
        required: true,
      },
      claimType: {
        type: String,
        required: true,
      },
      claimList: {
        type: Array,
        required: true,
      },
    },
    mixins: [tfbHelperMixin, tfbDataMixin],
    data() {
      return {
        coverageHeaders: [
          { text: 'Coverage', align: 'start', value: 'CoverageDescription' },
          { text: 'Amount Paid', value: 'CoverageAmount' },
        ],
      };
    },
  };
</script>
<style lang="scss">

</style>
