import { format, parseISO } from 'date-fns';
import { isNaN, kebabCase, replace } from 'lodash';

export default {
  computed: {
    imageRootPath() {
      return `${process.env.VUE_APP_ROOT_URI}/publishingimages/`;
    },
    isLoggedIn() {
      try {
        return this.$store.getters['member/loggedInStatus'];
      } catch (error) {
        return false;
      }
    },
    isMemberRole() {
      try {
        const memberRole = this.$store.getters['member/role'];
        if (memberRole === 'I') {
          return true;
        }
        return false;
      } catch (error) {
        return false;
      }
    },
    trackingId() {
      return this.$store.state.app.tfbId;
    },
  },
  filters: {
    formatBlogDate(date) {
      if (!date) return null;
      return format(parseISO(date.toString()), 'M.d.yy');
    },
    formatPhone(phone) {
      if (!phone || phone === null || phone === '') return null;
      if (phone.toString().toLowerCase().indexOf(' x') !== -1) {
        const cleanPhoneNumber = phone.replaceAll('-', '.').replaceAll(' x', ' Ext. ');
        return cleanPhoneNumber;
      }
      if (phone.toString().toLowerCase().indexOf('-') !== -1) {
        const cleanPhoneNumber = phone.replaceAll('-', '.');
        return cleanPhoneNumber;
      }
      if (phone.length === 10) {
        return `${phone.substr(0, 3)}.${phone.substr(3, 3)}.${phone.substr(6, 4)}`;
      }
      if (phone.length === 11) {
        return `${phone.substr(0, 1)}.${phone.substr(2, 3)}.${phone.substr(4, 3)}.${phone.substr(7, 4)}`;
      }
      return phone;
    },
    linkFormatPhone(phone) {
      if (!phone || phone === null || phone === '') return null;
      if (phone.toString().toLowerCase().indexOf('x') !== -1) {
        const strippedPhoneNumber = phone.substr(0, phone.indexOf('x'));
        const cleanPhoneNumber = strippedPhoneNumber.replaceAll(' ', '')
          .replaceAll('-', '')
          .replaceAll('.', '')
          .replaceAll('(', '')
          .replaceAll(')', '');
        return `tel:+1${cleanPhoneNumber}`;
      }
      if (phone.toString().toLowerCase().indexOf('-') !== -1) {
        const cleanPhoneNumber = phone.replaceAll(' ', '')
          .replaceAll('-', '')
          .replaceAll('.', '')
          .replaceAll('(', '')
          .replaceAll(')', '');
        return `tel:+1${cleanPhoneNumber}`;
      }
      if (phone.length === 10) {
        return `tel:+1${phone}`;
      }
      if (phone.length >= 11) {
        return `tel:+${phone}`;
      }
      return phone;
    },
    linkFormatEmail(email) {
      if (!email) return null;
      return `mailto:${email.trim().toLowerCase()}`;
    },
  },
  methods: {
    getBackgroundImage(src) {
      if (!src || src === '') {
        return { backgroundColor: '#003663' };
      }
      return { backgroundImage: `url(${src.toLowerCase()})` };
    },
    getHeaderBackgroundImage(src) {
      return { backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0) 70%, #ffffff 100%), url(${src.toLowerCase()})` };
    },
    getVideoBackgroundImage(src) {
      if (!src || src === '' || this.containsString(src.toLowerCase(), 'media%20player/videopreview.png')) {
        return { backgroundColor: '#666666' };
      }
      return {
        backgroundImage: `url(${src.toLowerCase()})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      };
    },
    kebabify(words) {
      return kebabCase(words);
    },
    formatTime(time) {
      if (!time) return null;
      if (time === '' || time === ' ') return null;
      return format(parseISO(time.toString()), 'hh:mm a');
    },
    formatDate(date) {
      try {
        if (!date) return null;
        if (date === '' || date === ' ') return null;
        if (date === '9999-12-31') return '12/31/9999';
        return format(parseISO(date.toString()), 'MM/dd/yyyy');
      } catch (e) {
        return '12/31/9999';
      }
    },
    parseDate(date) {
      try {
        if (!date) return null;
        if (date === '' || date === ' ') return null;
        return format(parseISO(date.toString()), 'yyyy-MM-dd');
      } catch (e) {
        return format(new Date().toISOString());
      }
    },
    formatYearDate(date) {
      if (!date) return null;
      if (date === '' || date === ' ') return null;
      return format(parseISO(date.toString()), 'yyyy');
    },
    formatMoney(amount, decimals) {
      if (!amount) return null;
      if (isNaN(amount)) {
        return amount;
      }
      if (this.containsString(amount, '%')) {
        return amount;
      }

      const a = amount.toString();
      const f = this.replaceAll(a, ',', '').replace('$', '').replace('(', '')
        .replace(')', '');
      let n = Number(f);
      const c = decimals;
      const d = '.';
      const t = ',';
      const s = n < 0 ? '-' : '';
      // eslint-disable-next-line prefer-template
      const i = parseInt(n = Math.abs(+n || 0).toFixed(c), 10) + '';
      const j = i.length > 3 ? i.length % 3 : 0;
      // eslint-disable-next-line prefer-template
      return s + '$' + (j ? i.substr(0, j) + t : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : '');
    },
    maskString(s, c, m) {
      const p = s.length - c;
      let v = s.substring(p);
      for (let i = 0; i < p; i += 1) {
        v = m + v;
      }
      return v;
    },
    containsString(sourceString, findString) {
      if (!sourceString || sourceString === null) return false;
      if (!findString || findString === null) return false;
      if (sourceString.toString().toLowerCase().indexOf(findString.toLowerCase()) !== -1) {
        return true;
      }
      return false;
    },
    escapeRegExp(str) {
      // eslint-disable-next-line no-useless-escape
      return str.replace(/([.*+?^=!:${}()|\[\]\/\\])/g, '\\$1');
    },
    prefixPadding(inputString, totalLength, padding) {
      let builtString = inputString;
      const paddingCount = totalLength - builtString.length;
      for (let i = 0; i < paddingCount; i += 1) {
        builtString = `${padding}${builtString}`;
      }
      return builtString;
    },
    replaceAll(inputString, findString, replaceString) {
      return replace(inputString, new RegExp(this.escapeRegExp(findString), 'g'), replaceString);
    },
    removePhoneFormatting(str) {
      if (!str) return null;
      let tempPhone = this.replaceAll(str, '-', '');
      tempPhone = this.replaceAll(tempPhone, '(', '');
      tempPhone = this.replaceAll(tempPhone, ')', '');
      tempPhone = this.replaceAll(tempPhone, '.', '');
      tempPhone = this.replaceAll(tempPhone, '/', '');
      tempPhone = this.replaceAll(tempPhone, ' ', '');

      return tempPhone.trim();
    },
    hasNAlphaCharacters(str, numberOfCharacters) {
      let finalReturn = false;
      let currentCount = 0;

      for (let i = 0; i < str.length; i += 1) {
        if (str[i] === str[i].toLowerCase() || str[i] === str[i].toUpperCase()) {
          currentCount += 1;
          if (currentCount > (numberOfCharacters - 1)) {
            finalReturn = true;
          }
        }
      }
      return finalReturn;
    },
    hasNLowercaseCharacters(str, numberOfCharacters) {
      let finalReturn = false;
      let currentCount = 0;

      for (let i = 0; i < str.length; i += 1) {
        if (str[i] === str[i].toLowerCase()) {
          currentCount += 1;
          if (currentCount > (numberOfCharacters - 1)) {
            finalReturn = true;
          }
        }
      }
      return finalReturn;
    },
    hasNUppercaseCharacters(str, numberOfCharacters) {
      let finalReturn = false;
      let currentCount = 0;

      for (let i = 0; i < str.length; i += 1) {
        if (str[i] === str[i].toUpperCase()) {
          currentCount += 1;
          if (currentCount > (numberOfCharacters - 1)) {
            finalReturn = true;
          }
        }
      }
      return finalReturn;
    },
    hasNNumericCharacters(str, numberOfCharacters) {
      // eslint-disable-next-line no-useless-escape
      const reg = new RegExp(`(\\D*\\d){${numberOfCharacters},}`, 'g');
      const testResult = reg.test(str);
      return testResult;
    },
    hasMoreThanNConcurrentLetters(str, numberOfCharacters) {
      let finalReturn = true;
      let currentCount = 0;
      let lastLetter = '';
      const splitStr = str.split('');

      splitStr.forEach((letter) => {
        if (lastLetter === letter) {
          currentCount += 1;
          if (currentCount > numberOfCharacters) {
            finalReturn = false;
          }
        }

        lastLetter = letter;
      });
      return finalReturn;
    },
    buildSingleLineAddress(lineOne, lineTwo, lineThree, city, state, zipCode, zipCodeFour, zipCodeTwo) {
      const addressArray = [];
      let returnAddress = '';

      if (lineOne !== null && lineOne !== '') {
        addressArray.push(`${lineOne} `);
      }

      if (lineTwo !== null && lineTwo !== '') {
        addressArray.push(`${lineTwo} `);
      }

      if (lineThree !== null && lineThree !== '') {
        addressArray.push(`${lineThree} `);
      }

      if (city !== null && city !== '') {
        addressArray.push(`${city}, `);
      }

      if (state !== null && state !== '') {
        addressArray.push(`${state} `);
      }

      if (zipCode !== null && zipCode !== '') {
        addressArray.push(`${zipCode}`);
      }

      if (zipCodeFour !== null && zipCodeFour !== '') {
        addressArray.push(`-${zipCodeFour}`);
      }

      if (zipCodeTwo !== null && zipCodeTwo !== '') {
        addressArray.push(`-${zipCodeTwo}`);
      }

      for (let a = 0; a < addressArray.length; a += 1) {
        returnAddress += addressArray[a];
      }

      return returnAddress.trim();
    },
    buildMapAddress(lineOne, lineTwo, lineThree, city, state, zipCode, zipCodeFour, zipCodeTwo) {
      let mapUrl = 'https://www.google.com/maps/dir//';

      if (lineOne !== null && lineOne.trim() !== '') {
        mapUrl += this.replaceAll(lineOne.trim(), ' ', '%20');
      }

      if (lineTwo !== null && lineTwo.trim() !== '') {
        mapUrl += `%20${this.replaceAll(lineTwo.trim(), ' ', '%20')}`;
      }

      if (lineThree !== null && lineThree.trim() !== '') {
        mapUrl += `%20${this.replaceAll(lineThree.trim(), ' ', '%20')}`;
      }

      if (city !== null && city.trim() !== '') {
        mapUrl += `%20${this.replaceAll(city.trim(), ' ', '%20')}`;
      }

      if (state !== null && state.trim() !== '') {
        mapUrl += `%20${this.replaceAll(state.trim(), ' ', '%20')}`;
      }

      if (zipCode !== null && zipCode.trim() !== '') {
        mapUrl += `%20${this.replaceAll(zipCode.trim(), ' ', '%20')}`;
      }

      if (zipCodeFour !== null && zipCodeFour.trim() !== '') {
        mapUrl += this.replaceAll(zipCodeFour.trim(), ' ', '%20');
      }

      if (zipCodeTwo !== null && zipCodeTwo.trim() !== '') {
        mapUrl += this.replaceAll(zipCodeTwo.trim(), ' ', '%20');
      }

      return mapUrl.trim();
    },
    buildAddress(lineOne, lineTwo, lineThree, city, state, zipCode, zipCodeFour, zipCodeTwo) {
      const addressArray = [];
      let returnAddress = '';

      if (lineOne !== undefined && lineOne !== null && lineOne.trim() !== '') {
        addressArray.push(`<span itemprop="streetAddress">${lineOne.trim()}</span><br />`);
      }

      if (lineTwo !== undefined && lineTwo !== null && lineTwo.trim() !== '') {
        addressArray.push(`${lineTwo.trim()}<br />`);
      }

      if (lineThree !== undefined && lineThree !== null && lineThree.trim() !== '') {
        addressArray.push(`${lineThree.trim()}<br />`);
      }

      if (city !== undefined && city !== null && city.trim() !== '') {
        addressArray.push(`<span itemprop="addressLocality">${city.trim()}</span>, `);
      }

      if (state !== undefined && state !== null && state.trim() !== '') {
        addressArray.push(`<span itemprop="addressRegion">${state.trim()}</span> `);
      }

      addressArray.push('<span itemprop="postalCode">');
      if (zipCode !== undefined && zipCode !== null && zipCode.trim() !== '') {
        addressArray.push(`${zipCode.trim()}`);
      }

      if (zipCodeFour !== undefined && zipCodeFour !== null && zipCodeFour.trim() !== '') {
        addressArray.push(`-${zipCodeFour.trim()}`);
      }

      if (zipCodeTwo !== undefined && zipCodeTwo !== null && zipCodeTwo.trim() !== '') {
        addressArray.push(`-${zipCodeTwo.trim()}`);
      }
      addressArray.push('</span><br />');

      for (let a = 0; a < addressArray.length; a += 1) {
        returnAddress += addressArray[a];
      }

      return returnAddress.trim();
    },
    buildAgentInfoBox(title, lineOne, lineTwo, city, state, zipCode) {
      const infoBoxArray = [];
      let returnInfoBox = '';

      infoBoxArray.push(`<div id="content"><div id="bodyContent"><p class="font-weight-bold">${title}</p><p>`);

      if (lineOne !== null && lineOne.trim() !== '') {
        infoBoxArray.push(`<span itemprop="streetAddress">${lineOne.trim()}</span><br />`);
      }

      if (lineTwo !== null && lineTwo.trim() !== '') {
        infoBoxArray.push(`${lineTwo.trim()}<br />`);
      }

      if (city !== null && city.trim() !== '') {
        infoBoxArray.push(`<span itemprop="addressLocality">${city.trim()}</span>, `);
      }

      if (state !== null && state.trim() !== '') {
        infoBoxArray.push(`<span itemprop="addressRegion">${state.trim()}</span> `);
      }

      if (zipCode !== null && zipCode.trim() !== '') {
        infoBoxArray.push(`<span itemprop="postalCode">${zipCode.trim()}</span>`);
      }

      infoBoxArray.push('</p></div></div>');

      for (let a = 0; a < infoBoxArray.length; a += 1) {
        returnInfoBox += infoBoxArray[a];
      }

      return returnInfoBox.trim();
    },
    buildOfficeInfoBox(title, countyName, branchCode, lineOne, lineTwo, city, state, zipCode) {
      const infoBoxArray = [];
      let returnInfoBox = '';
      let builtTitle = title;

      if (branchCode !== '') {
        builtTitle += ` - ${branchCode.toUpperCase()}`;
      }

      infoBoxArray.push(`<div id="content"><div id="bodyContent"><p class="font-weight-bold">${builtTitle}</p><p>`);

      if (lineOne !== null && lineOne.trim() !== '') {
        infoBoxArray.push(`<span itemprop="streetAddress">${lineOne.trim()}</span><br />`);
      }

      if (lineTwo !== null && lineTwo.trim() !== '') {
        infoBoxArray.push(`${lineTwo.trim()}<br />`);
      }

      if (city !== null && city.trim() !== '') {
        infoBoxArray.push(`<span itemprop="addressLocality">${city.trim()}</span>, `);
      }

      if (state !== null && state.trim() !== '') {
        infoBoxArray.push(`<span itemprop="addressRegion">${state.trim()}</span> `);
      }

      if (zipCode !== null && zipCode.trim() !== '') {
        infoBoxArray.push(`<span itemprop="postalCode">${zipCode.trim()}</span>`);
      }
      infoBoxArray.push('<br /><br />');

      if (branchCode !== '') {
        infoBoxArray.push(`<a href="/county/details/${this.replaceAll(countyName, ' ', '-').toLowerCase()}/${branchCode.toLowerCase()}">More Information About This Office</a></p></div></div>`);
      } else {
        infoBoxArray.push(`<a href="/county/details/${this.replaceAll(countyName, ' ', '-').toLowerCase()}">More Information About This Office</a></p></div></div>`);
      }

      infoBoxArray.push('</p></div></div>');

      for (let a = 0; a < infoBoxArray.length; a += 1) {
        returnInfoBox += infoBoxArray[a];
      }

      return returnInfoBox.trim();
    },
    isEmailAddress(str) {
      // eslint-disable-next-line no-useless-escape
      const regEx = new RegExp(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/igm);
      return regEx.test(str);
    },
    valueCheck(inputItem) {
      if (!inputItem) return false;
      if (inputItem === null) return false;
      return true;
    },
    doRedirect() {
      // eslint-disable-next-line no-lonely-if
      if (this.$store.getters['member/role'] !== 'I') {
        // Not the insured; go directly to Account Summary
        this.$router.push({ name: 'policyholderAccountSummary' });
      } else if (this.$store.getters['member/conversionIndicator'] === true) {
        // Need to convert user name
        this.$router.push({ name: 'policyholderIdConversion' });
      } else if (this.$store.getters['member/verifiedIndicator'] === false) {
        // Need to verify email address
        this.$router.push({ name: 'policyholderEmailVerification' });
      } else if (this.$store.getters['member/resetIndicator'] === true) {
        // Need to reset password
        this.$router.push({ name: 'policyholderResetPassword' });
      } else if (this.$store.getters['app/accessPage'] !== null) {
        // We have a previous access page so go to it
        const savedAccessPage = this.$store.getters['app/accessPage'];
        const memberNumber = this.$store.getters['member/memberNumber'];

        this.$store.dispatch('policy/getAccountSummary', { sMemberNumber: memberNumber, sTfbId: this.trackingId })
          .then(() => {
            // Clear the access page
            this.$store.dispatch('app/setAccessPage', null);

            // Redirect to the access page
            this.$router.push(savedAccessPage);
          })
          .catch((error) => {
            if (error.toString().indexOf('status code 401') >= 0) {
              // Session has timed out, log out and go to login
              this.$store.dispatch('app/setIsLoading', true);
              // Clear the access page
              this.$store.dispatch('app/setAccessPage', null);
              this.$store.dispatch('member/doLogout')
                .then(() => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$router.push({ name: 'policyholderLogin' });
                })
                .catch((accessError) => {
                  this.$store.dispatch('app/setIsLoading', false);
                  this.$store.dispatch('app/setErrorMessage', accessError.toString());
                });
            }
            this.$store.dispatch('app/setErrorMessage', error.toString());
          });
      } else {
        // Go to Account Summary
        this.$router.push({ name: 'policyholderAccountSummary' });
      }
    },
    doStatusCheck() {
      if (this.$store.getters['member/resetIndicator'] === true) {
        // Need to reset password
        this.$router.push({ name: 'policyholderResetPassword' });
      } else if (this.$store.getters['member/conversionIndicator'] === true) {
        // Need to convert user name
        this.$router.push({ name: 'policyholderIdConversion' });
      } else if (this.$store.getters['member/verifiedIndicator'] === false) {
        // Need to verify email address
        this.$router.push({ name: 'policyholderEmailVerification' });
      }

      return true;
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  },
};
